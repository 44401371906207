<template>
    <transition name="collapse" v-bind="$attrs" v-on="$listeners" @enter="begin" @after-enter="end" @before-leave="begin" @after-leave="end">
        <div v-show="!collapsed" class="collapse">
            <slot></slot>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Collapse',
    props: {
        collapsed: Boolean
    },
    methods: {
        begin(element) {
            const event = this.collapsed ? 'begin-close' : 'begin-open'
            this.$emit(event)
            element.style.display = 'block'
            let height = element.scrollHeight + 'px'
            element.style.display = ''
            element.style.height = height
        },
        end(element) {
            const event = this.collased ? 'end-close' : 'end-open'
            this.$emit(event)
            element.style.height = ''
        }
    }
}
</script>

<style scoped>
.collapse {
    overflow-y: none;
}
.collapse-enter,
.collapse-leave-to {
    height: 0!important;
}
.collapse-enter-active,
.collapse-leave-active {
    overflow: hidden;
    transition: height .2s ease;
}
</style>
