<template>
    <div>
        <div class="header">{{ group.name }}</div>
        <div class="group-actions">
            <button @click="editing = !editing" class="btn-link">Change name or passphrase</button>
            <button @click="deleteGroup" class="btn-link">Delete group</button>
        </div>
        <Collapse :collapsed="!editing">
            <form class="edit-group">
                <div>Name</div>
                <div>
                    <input type="text">
                    <button>
                        <FontAwesomeIcon icon="check"/>
                    </button>
                </div>
                <div>Password</div>
                <div>
                    <input type="password">
                    <button>
                        <FontAwesomeIcon icon="check"/>
                    </button>
                </div>
            </form>
        </Collapse>
        <Actions
            :photos="photos"
            @delete-photos="removePhotos">
        </Actions>
        <div class="photo-list">
            <Photo v-for="photo in photos" :key="photo.md5" :photo="photo"></Photo>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Collapse from '@/components/Collapse'
import Actions from '@/components/Actions'
import Photo from '@/components/Photo'

export default {
    name: 'Group',
    data() {
        return {
            photos: [],
            group: {},
            editing: false
        }
    },
    computed: {
        checked() {
            return this.photos.filter(photo => photo.checked)
        },
        anyChecked() {
            return this.checked.length > 0
        }
    },
    components: {
        FontAwesomeIcon,
        Collapse,
        Actions,
        Photo
    },
    methods: {
        load(id) {
            this.axios.get('admin/photos/', {params: {group: id}})
            .then(resp => {
                resp.data.results.forEach(photo => photo.checked = false)
                this.photos.push(...resp.data.results)
            })
            .catch(() => {})
            this.axios.get('admin/group/', {params: {groupid: id}})
            .then(resp => {
                this.group = resp.data.group
            })
            .catch(() => {})
        },
        editGroup() {

        },
        deleteGroup() {

        },
        updatePosition(e) {
            const update = {
                md5: e.moved.element.md5,
                to: e.moved.newIndex,
                from: e.moved.oldIndex
            }
            this.axios.post('admin/photos/move/', update)
            .catch(() => {})
        },
        removePhotos(photos) {
            photos.forEach(photo => {
                this.photos.splice(this.photos.indexOf(photo), 1)
            })
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.photos = []
            vm.load(to.params.id)
        })
    },
    beforeRouteUpdate(to, from, next) {
        this.photos = []
        this.load(to.params.id)
        next()
    }
}
</script>

<style scoped>
.header {
    font-size: 2rem;
    font-weight: bold;
    padding: 1rem;
}
.group-actions {
    display: flex;
    justify-content: space-between;
}
.edit-group {
    padding: 1rem;
}
.btn-link {
    background: none;
    border: none;
    padding: .25rem .5rem;
    margin: 0 .5rem;
    color: blue;
    cursor: pointer;
    font-size: 1rem;
}
.photo-list {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem;
}
</style>